import { render, staticRenderFns } from "./projectList.vue?vue&type=template&id=64a5bf1c&scoped=true&"
import script from "./projectList.vue?vue&type=script&lang=js&"
export * from "./projectList.vue?vue&type=script&lang=js&"
import style0 from "./projectList.vue?vue&type=style&index=0&id=64a5bf1c&prod&lang=scss&"
import style1 from "./projectList.vue?vue&type=style&index=1&id=64a5bf1c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a5bf1c",
  null
  
)

export default component.exports