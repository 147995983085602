<template>
    <!-- 素材列表页 -->
    <div class="pdf">
        <!-- 左侧模块 -->
        <div class="left-module">
            <!-- 项目列表 -->
            <div>
                <table>
                    <tr :id="item.project_id" 
                        v-for="(item, index) in reportList"
                        :key="index"
                        class="tr-border">
                        <!-- 排序 -->
                        <td class="width3">
                            {{item.index}}：
                        </td>
                        <!-- 项目名称 -->
                        <td class="td-project" @click="getReportDetail(item.project_id,item.name)">
                            <span v-if="item.status == 0" class="status">(未提交)</span>
                            <span v-if="item.status == 1" class="status">(已提交)</span>
                            <span v-if="item.status == 2" class="status">(已评阅)</span>
                            <span>{{item.name}}</span>
                        </td>
                    </tr>                 
                </table>
            </div>
        </div>

        <!-- 右侧模块 -->
        <div class="div" >
            <div v-if="rightContentShow" id="scrolldIV">
                <!-- 未提交 -->
                <div v-if="report_status == 0">
                    <!-- 按钮列表 -->
                    <div class="button-div">
                        <div class="divs" >
                            <span class="button-span">
                                <el-button @click="resetReport()">重置报告</el-button>
                            </span>
                            <span class="button-span">
                                <el-button type="primary" @click="saveReport()">保存报告</el-button>
                            </span>
                            <span class="button-span">
                                <el-button type="success" @click="putReport()"> 提交报告</el-button>
                            </span>
                        </div>
                    </div>
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" >
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="left span-answer" v-if="!item.must_answer" >(非必答)</span>
                            <span class ="left span-index" >{{index + 1}}：</span>
                            <span v-html="item.title" class="span-title"></span>
                            <span class="right span-score" >{{item.score}} 分</span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <div v-if="item.type == 'image' || item.type == 'text'">
                            <vue-ueditor-wrap
                                v-model="item.value"
                                :config="myConfig"
                                @ready="ready"
                            ></vue-ueditor-wrap>
                        </div>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'">

                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" placeholder="请输入内容" ></el-input>
                                    </div>
                                    <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" placeholder="请输入内容" ></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input v-model="item.table[trIndex][tdIndex]" disabled="disabled"></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input disabled="disabled"></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        
                    </div>
                </div>
                <!-- 已提交  未评阅 -->
                <div v-if="report_status == 1">
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" >
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="left span-answer" v-if="!item.must_answer" >(非必答)</span>
                            <span class ="left span-index" >{{index + 1}}：</span>
                            <span v-html="item.title" class="span-title"></span>
                            <span class="right span-score" >{{item.score}} 分</span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <div v-if="item.type == 'image' || item.type == 'text'" v-html="item.value"></div>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'">

                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input v-model="item.table[trIndex][tdIndex]" disabled="disabled"></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input disabled="disabled"></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <!-- 已评阅 -->
                <div v-if="report_status == 2">
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" >
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="left span-answer" v-if="!item.must_answer" >(非必答)</span>
                            <span class ="left span-index" >{{index + 1}}：</span>
                            <span v-html="item.title" class="span-title"></span>
                            <span class="right span-score" >{{item.truescore}}/{{item.score}} 分</span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <div v-if="item.type == 'image' || item.type == 'text'" v-html="item.value"></div>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'">

                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables disabled">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input v-model="item.table[trIndex][tdIndex]" disabled="disabled"></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input disabled="disabled"></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <br>
                        <hr >
                        <!-- 批注（涂鸦） -->
                        <div class="tuya-title" v-if="item.tuya">教师批注：</div>
                        <div v-if="item.tuya" v-html="item.tuya"></div>
                        <!-- 标注 -->
                        <div class="bz-title" v-if="item.channelstr.length > 0">教师标注：</div>
                        <div v-if="item.channelstr.length > 0">
                            <span v-for="(it,ind) in item.channelstr" :key="ind" class="bz-item">{{it}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="recover-button">
                    <h3>暂无数据~</h3><br>
                </div>
            </div>
        </div>

        <!-- 重置/提交报告弹窗 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogShow"
            width="30%">
            <span v-if="dialogSign == 'reset'">
                报告重置后，已提交的所有信息都会被清空！
            </span>
            <span v-if="dialogSign == 'put'">
                确定要提交报告吗？ <br>
                报告提交后不可修改！！！
            </span>
            <span v-if="dialogSign == 'update'">
                报告模板已被老师修改，是否重置报告？
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="resetReportExec()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" >
    .el-dialog__body{
        padding: 30px 20px ;
        padding-top: 5px !important;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
        padding-bottom: 30px;
    }
    .el-table .brown {
        background-color: #FAFAFA;
    }
    .el-input.is-disabled .el-input__inner {
        border-color: #E4E7ED;
        cursor: not-allowed;
        background-color:#9ba7a9;
        color:#FFF;
    }
</style>
<style lang="scss" scoped>
    @import "@/assets/css/student/report/reportList.scss";
</style>

<script>
import reportList from "@/assets/js/student/report/reportList.js";
export default {
    ...reportList
};
</script>
